const CreateRoleMutation = `mutation CreateRole($roleName: String) {
  createRole(role_name: $roleName) {
    id
    role_name
    createdAt
    updatedAt
  }
}`;

const DeleteRoleMutation = `mutation DeleteRole($deleteRoleId: ID!) {
  deleteRole(id: $deleteRoleId)
}`;

const LoginMutation = `mutation($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      id
      first_name,
      last_name,
      email,
      role_id
    }
  }
}`;

const RequestPasswordResetMutation = `mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email) {
    success
    message
  }
}`;

const ResetPasswordMutation = `mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword) {
    success
    message
  }
}`;

const CreateUserMutation = `mutation CreateUser($firstName: String!, $lastName: String!, $phone: String!, $email: String!, $password: String!, $roleId: Int!, $createdBy: Int!) {
  createUser(first_name: $firstName, last_name: $lastName, phone: $phone, email: $email, password: $password, role_id: $roleId, created_by: $createdBy) {
    id
    first_name
    last_name
    email
    phone
    password
    reset_password
    role_id
    createdAt
    updatedAt
  }
}`;

const DeleteUserMutation = `mutation DeleteUser($deleteUserId: ID!) {
  deleteUser(id: $deleteUserId)
}`;

const CreateBuildingMutation = `mutation CreateBuilding($name: String!, $userId: Int!, $address: String!, $noOfUnits: Int!) {
  createBuilding(name: $name, user_id: $userId, address: $address, no_of_units: $noOfUnits) {
    success
    message
    building {
      id
      name
      landlord_id
      address
      no_of_units
      createdAt
      updatedAt
    }
  }
}`;

const UpdateBuildingMutation = `mutation UpdateBuilding($updateBuildingId: ID!, $userId: ID!, $name: String, $noOfUnits: Int) {
  updateBuilding(id: $updateBuildingId, user_id: $userId, name: $name, no_of_units: $noOfUnits) {
    id
    name
    landlord_id
    address
    no_of_units
    createdAt
    updatedAt
  }
}`;

const DeleteBuildingMutation = `mutation DeleteBuilding($deleteBuildingId: ID!) {
  deleteBuilding(id: $deleteBuildingId)
}`;


const UpdateUnitMutation = `mutation UpdateUnit($updateUnitId: ID!, $unitNumber: String!, $meterNumber: Int!) {
  updateUnit(id: $updateUnitId, unit_number: $unitNumber, meter_number: $meterNumber) {
    id
    unit_number
    building_id
    meter_number
    available
    createdAt
    updatedAt
  }
}`;


const CreateRentalMutation = `mutation CreateRental($firstName: String!, $lastName: String!, $email: String!, $phone: String!, $userIdentification: String!, $password: String!, $unitId: Int!, $startDate: String!, $rentAmount: Float!, $rentDeposit: Float!, $waterDeposit: Float!, $elecDeposit: Float!, $serviceCharge: Float!, $waterReading: Int!, $createdBy: Int!) {
  createRental(first_name: $firstName, last_name: $lastName, email: $email, phone: $phone, user_identification: $userIdentification, password: $password, unit_id: $unitId, start_date: $startDate, rent_amount: $rentAmount, rent_deposit: $rentDeposit, water_deposit: $waterDeposit, elec_deposit: $elecDeposit, service_charge: $serviceCharge, water_reading: $waterReading, created_by: $createdBy) {
    first_name
    last_name
    email
    phone
    user_identification
    unit_id
    start_date
    end_date
    rent_amount
    rent_deposit
    water_deposit
    elec_deposit
    service_charge
    water_reading
    created_by
  }
}`;


const UpdateRentalMutation = `mutation UpdateRental($updateRentalId: ID!, $userIdentification: String!, $firstName: String, $lastName: String, $email: String, $phone: String, $startDate: String, $endDate: String, $rentAmount: Float, $rentDeposit: Float, $waterDeposit: Float, $elecDeposit: Float, $serviceCharge: Float, $waterReading: Float) {
  updateRental(id: $updateRentalId, user_identification: $userIdentification, first_name: $firstName, last_name: $lastName, email: $email, phone: $phone, start_date: $startDate, end_date: $endDate, rent_amount: $rentAmount, rent_deposit: $rentDeposit, water_deposit: $waterDeposit, elec_deposit: $elecDeposit, service_charge: $serviceCharge, water_reading: $waterReading) {
    id
    tenant_id
    unit_id
    start_date
    end_date
    user_identification
    rent_amount
    rent_deposit
    water_deposit
    elec_deposit
    service_charge
    water_reading
    createdAt
    updatedAt
  }
}`;

const DeleteRentalMutation = `mutation DeleteRental($deleteRentalId: ID!) {
  deleteRental(id: $deleteRentalId)
}`;


const CreateInvoicesMutation = `mutation CreateInvoice($rentalId: Int!, $invoiceDate: String!, $rentAmount: Float!, $waterAmount: Float!, $elecAmount: Float!, $totalAmount: Float!, $status: Int!) {
  createInvoice(rental_id: $rentalId, invoice_date: $invoiceDate, rent_amount: $rentAmount, water_amount: $waterAmount, elec_amount: $elecAmount, total_amount: $totalAmount, status: $status) {
    id
    rental_id
    invoice_date
    rent_amount
    water_amount
    elec_amount
    total_amount
    status
    created_at
    updated_at
  }
}`;

const GenerateInvoicesMutation = `mutation GenerateInvoices($buildingId: Int!, $invoiceDate: String!) {
  generateInvoices(building_id: $buildingId, invoice_date: $invoiceDate) {
    id
    rental_id
    unit_id
    unit_number
    invoice_date
    rent_amount
    water_amount
    elec_amount
    service_amount
    total_amount
    status
  }
}`;

const CreateWaterBillingsMutation = `mutation CreateWaterBilling($rentalId: Int!, $previousReading: Int!, $currentReading: Int!, $consumption: Int!, $rate: Float!, $extraChargeAmount: Float!, $amount: Float!, $billingDate: String!) {
  createWaterBilling(rental_id: $rentalId, previous_reading: $previousReading, current_reading: $currentReading, consumption: $consumption, rate: $rate, extra_charge_amount: $extraChargeAmount, amount: $amount, billing_date: $billingDate) {
    id
    rental_id
    meter_number
    previous_reading
    current_reading
    consumption
    rate
    extra_charge_amount
    amount
    billing_date
    createdAt
    updatedAt
  }
}`;

const CreatePaymentMutation = `mutation CreatePayment($invoiceId: ID!, $paymentDate: String!, $amount: Float!, $paymentCode: String!) {
  createPayment(invoice_id: $invoiceId, payment_date: $paymentDate, amount: $amount, payment_code: $paymentCode) {
    id
    invoice_id
    rental_id
    payment_date
    amount
    balance
    over_pay
    payment_code
    status
    createdAt
    updatedAt
  }
}`;

const UpdateUserMutation = `mutation UpdateUser($updateUserId: ID!, $firstName: String!, $lastName: String!, $phone: String!, $email: String) {
  updateUser(id: $updateUserId, first_name: $firstName, last_name: $lastName, phone: $phone, email: $email) {
    id
    first_name
    last_name
    email
    phone
    password
    reset_password
    role_id
    created_by
    createdAt
    updatedAt
  }
}`;

export {
  CreateRoleMutation,
  DeleteRoleMutation,
  LoginMutation,
  ResetPasswordMutation,
  RequestPasswordResetMutation,
  CreateUserMutation,
  DeleteUserMutation,
  CreateBuildingMutation,
  UpdateBuildingMutation,
  DeleteBuildingMutation,
  UpdateUnitMutation,
  CreateRentalMutation,
  UpdateRentalMutation,
  DeleteRentalMutation,
  CreateInvoicesMutation,
  GenerateInvoicesMutation,
  CreateWaterBillingsMutation,
  CreatePaymentMutation,
  UpdateUserMutation
};
import { cloneElement } from 'react';
import { AuthProvider } from './auth';
import { withRouter } from '../helpers';


function ProviderComposer({ contexts, children }) {
    return contexts.reduceRight(
        (kids, parent) => cloneElement(parent, {
            children: kids,
        }),
        children,
    );
}

const ContextProvider = withRouter(({ children }) => (
    <ProviderComposer contexts={
        [<AuthProvider />]}
    >
        {children}
    </ProviderComposer>
));

export { ContextProvider };
